<template>
  <div class="col-md-12 col-sm-12 col-lg-12">
    <div
      class="jumbotron background-title row text-center d-flex align-items-center"
    >
      <h1 class="col-12 text-center mb-5 page-title">¿HABLAMOS?</h1>
      <div class="page-info col-12">
        Para brindarle más información acerca de nuestros servicios y responder
        a todas sus inquietudes puede diligenciar el formulario o contactarse
        con nosotros vía email @gmail.com
        <br /><br />
        OFICINA: Cra 14 # 22 Norte - 38 Piso 3 Armenia - Quindío <br />
        CELULAR: 314 8033894
      </div>
    </div>

    <div class="container-form my-5">
      <form class="" id="form-contact">
        <div class="d-flex align-items-center justify-content-center">
          <div class="col-12 col-md-8 col-lg-5">
            <div class="col-12">
              <input
                v-model="formData.name"
                type="text"
                name="name"
                id="input-name"
                value=""
                placeholder="Nombre"
                required=""
              />
            </div>
            <div class="col-12">
              <input
                v-model="formData.subject"
                type="text"
                name="subject"
                id="input-subject"
                value=""
                placeholder="Asunto"
                required=""
              />
            </div>
            <div class="col-12">
              <textarea
                v-model="formData.message"
                name="message"
                id="input-message"
                placeholder="Mensaje"
                rows="6"
              ></textarea>
            </div>
            <div class="col-12 mt-4 text-center">
              <button
                type="button"
                class="no-border-button"
                @click.prevent="sendForm()"
              >
                Contáctanos
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row text-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.1900148039676!2d-75.6569692852956!3d4.559825544132784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38f4e813a64bf7%3A0xe4c4ff8529b38a44!2sCl.%2022%20Nte.%20%2338%2C%20Armenia%2C%20Quind%C3%ADo!5e0!3m2!1ses!2sco!4v1605040755468!5m2!1ses!2sco"
        width="100%"
        height="599"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Contact",
  title: "G y C Marketing | Contactanos",
  data() {
    return {
      formData: {
        name: null,
        subject: null,
        message: null,
      },
    };
  },
  methods: {
    sendForm() {
      if (
        this.formData.name === null ||
        this.formData.name === "" ||
        this.formData.subject === null ||
        this.formData.subject === "" ||
        this.formData.message === null ||
        this.formData.message === ""
      ) {
        this.$swal({
          icon: "warning",
          title: "¡Atención!",
          text: "Todos los campos son requeridos",
        });

        return;
      }

      axios
        .post("/api/contact.php", this.formData)
        .then((e) => {
          if (e.data.success) {
            this.formData.name = null;
            this.formData.subject = null;
            this.formData.message = null;

            this.$swal({
              icon: "success",
              title: "¡Correcto!",
              text: "¡Mensaje enviado exitosamente!",
              timer: 8000,
            });
          } else {
            this.$swal({
              icon: "error",
              text: "No se ha podido enviar el mensaje. Intente mas tarde.",
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: "No se ha podido enviar el mensaje. Intente mas tarde.",
          });
        });
    },
  },
};
</script>

<style>
body,
html {
  color: black !important;
  font-family: "Libre Baskerville", sans-serif;
}

.background-title {
  background-color: #ff9087 !important;
  min-height: 414px;
  border-radius: 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #bfbfbf;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0;
  text-decoration: none;
  width: 100%;
  margin-bottom: 15px;
}

.no-border-button {
  border: none;
  background: white;
  border-radius: 2px;
  padding: 5px 10px;
}

.no-border-button:hover {
  transition: all 500ms ease;
  background: #dadada;
}

.page-title {
  color: black;
  font-family: fantasy;
  font-size: 70px;
  letter-spacing: 5px;
}

.page-info {
  font-size: 18px;
  color: black;
}
h1,
h2,
h3,
h4 {
  font-family: "Anton", sans-serif !important;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .page-info {
    font-size: 12px;
    color: black;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }
}

@media screen and (max-width: 620px) {
  .page-title {
    font-size: 30px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }

  .background-title {
    min-height: 230px;
  }
}
</style>