<template>
  <div>
    <section id="about_us">
      <div class="container py-4">
        <div class="col-12">
          <h1 class="text-center">NOSOTROS</h1>
        </div>
        <div class="col-12 pt-4">
          <p id="somos_compania">
            <strong
              >Somos una compañía joven de tecnología multidisciplinar
              especializada en desarrollo de contenidos digitales,</strong
            >
            brindamos asesoría desde la fase inicial de la construcción de una
            marca hasta la implementación de la estrategia de comunicación y
            promoción en todas las áreas así como servicios de IT avanzados.
          </p>
        </div>
      </div>
    </section>

    <section id="marketing_values">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-4 d-flex align-items-center">
            <div class="text-center">
              <h1 class="mx-auto animate__animated animate__slideInLeft">
                SOLUCIONES RÁPIDAS Y ÓPTIMAS
              </h1>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <img id="imageOne" src="../assets/imageOne.png" alt="" />
          </div>
          <div class="col-12 col-md-4 d-flex align-items-center">
            <div class="text-justify py-3">
              <p id="our_work">
                Todo nuestro trabajo se hace con pasión, buscamos generar
                vínculos emocionales duraderos entre las marcas y sus clientes;
                siendo conscientes que los productos del futuro deben llamar a
                nuestros corazones, no a nuestras mentes.
              </p>
              <p>
                Nuestra agencia cuenta con una estructura multidisciplinaria que
                nos permite la especialización en distintas áreas; promoviendo
                un intercambio ideológico constante que aporte valor agregado a
                nuestro proceso creativo, desarrollando conceptos y estrategias
                impactantes y efectivas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="col-12 text-center py-2">
        <span id="brand_title"
          ><span id="fake">Valores</span>Valores de Marca</span
        >
      </div>
      <div class="col-12" id="branding_values">
        <div class="row">
          <div class="col-12 col-md-8 col-lg-3 mx-auto">
            <div class="row">
              <img src="../assets/4989.jpg" alt="branding_values" />
            </div>
          </div>
          <div class="col-12 col-lg-6 py-2">
            <p class="text-center">
              <strong>En G &amp; C Marketing y Publicidad</strong>
              queremos entender y atender las necesidades de nuestros clientes y
              ofrecerles las mejores soluciones, facilitándoles un equipo de
              profesionales como un departamento más de su propia empresa.
              Nuestros valores y resultados nos definen, por eso actuamos con la
              mayor honestidad y profesionalidad, trabajando por proyectos,
              brindando las mejores ventajas en precio y calidad.
            </p>

            <div class="row">
              <div
                class="col-12 col-lg-4 col-sm-4 col-md-4 animate__animated animate__slideInLeft"
              >
                <p class="font-weight-bold text-center">Calidad</p>
                <p class="text-center">
                  No importa la magnitud de su proyecto, va a tener un producto
                  final que le permita cumplir sus objetivos.
                </p>
              </div>
              <div
                class="col-12 col-lg-4 col-sm-4 col-md-4 animate__animated animate__slideInLeft"
              >
                <p class="font-weight-bold text-center">Asesoramiento</p>
                <p class="text-center">
                  Nuestro servicio va más allá, le daremos sugerencias y
                  propuestas que pueden mejorar sus expectativas iniciales
                </p>
              </div>
              <div
                class="col-12 col-lg-4 col-sm-4 col-md-4 animate__animated animate__slideInLeft"
              >
                <p class="font-weight-bold text-center">Innovación</p>
                <p class="text-center">
                  No importa la magnitud de su proyecto, va a tener un producto
                  final que le permita cumplir sus objetivos.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-3 mx-auto">
            <div class="row">
              <img src="../assets/cel.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
img {
  width: 100% !important;
}
#about_us {
  background: #fffb8f;
}

#marketing_values {
  padding: 2rem 0;
}

#branding_values {
  background: #fffb8f;
}

/*.side_images {
  height: 618px;
  width: 665px;
}

#marca-amarilla {
  width: 1024px;
  max-width: 1024px;
  min-height: 618px;
}
*/
#brand_title {
  font-weight: 400 !important;
  font-size: 27px;
  text-transform: uppercase;
  z-index: 999;
  position: relative;
}

#fake {
  background: #fffb8f;
  margin-left: -25px;
  position: absolute;
  height: 20px;
  color: #fffb8f;
  z-index: -1;
}

#somos_compania {
  font-size: 16px;
}

@media only screen and (max-width: 375px) {
  #about_us h1 {
    font-size: 30px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }

  #about_us p {
    font-size: 14px;
  }

  #marketing_values h1 {
    font-size: 35px;
  }

  #marketing_values p {
    font-size: 14px;
  }

  #branding_values p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 575px) {
  #about_us h1 {
    font-size: 30px;
  }

  #about_us p {
    font-size: 14px;
  }

  #marketing_values h1 {
    font-size: 35px;
  }

  #marketing_values p {
    font-size: 13px;
  }

  #branding_values p {
    font-size: 14px;
  }

  #imageOne {
    height: 340px !important;
  }

  #branding_values p {
    font-size: 12px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }

  #about_us h1 {
    font-size: 30px;
  }

  #about_us p {
    font-size: 14px;
  }

  #marketing_values h1 {
    font-size: 35px;
  }

  #marketing_values p {
    font-size: 13px;
  }

  #branding_values p {
    font-size: 14px;
  }

  #imageOne {
    height: 340px !important;
  }

  #branding_values p {
    font-size: 12px;
  }

  #branding_values img {
    height: 340px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #about_us h1 {
    font-size: 45px;
  }

  #about_us p {
    font-size: 17px;
  }

  #marketing_values h1 {
    font-size: 55px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }

  #marketing_values p {
    font-size: 17px;
  }

  #branding_values p {
    font-size: 17px;
  }

  #imageOne {
    height: 340px !important;
  }

  #branding_values p {
    font-size: 17px;
  }

  #branding_values img {
    height: 340px;
  }
}
</style>

<script>
export default {
  name: "About",
  title: "G y C Marketing | Acerca de nosotros",
};
</script>