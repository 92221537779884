<template>
  <div>
    <section id="intro">
      <div class="stripe-blue">
        <div class="row">
          <div class="w-75 mx-auto">
            <br />
            <p class="font_title">SERVICIOS</p>
            <p class="text_service">
              <strong
                >G y C Marketing y Publicidad&nbsp;es&nbsp;un grupo empresarial
                joven&nbsp;especializado en la creación y desarrollo de negocios
                online, que obtiene&nbsp;resultados para&nbsp;satisfacen las
                necesidades de ustedes como clientes.</strong
              >
              Porque&nbsp;somos un proveedor estratégico de tecnología, que
              busca convertirse&nbsp;en su socio de negocios.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="content" class="py-3">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="">
              <img src="../assets/lotus.png" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/aq.png" class="img_colum" />
            </div>
            <div class="">
              <img
                src="../assets/Lente_de_la_cámara_de_vídeo.png"
                class="img_colum"
              />
            </div>
            <div class="">
              <img src="../assets/tarjetas.png" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/DSC_3084.jpg" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/Graficosweb.png" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/Sitio_web_receptivo.png" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/Revista_diseno.png" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/18699.jpg" class="img_colum" />
            </div>
            <div class="">
              <img src="../assets/Modelo_masculino.png" class="img_colum" />
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="">
              <section id="servicios_web" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>SERVICIOS
                              WEB</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                      <p>
                        Nuestro equipo de diseñadores y desarrolladores
                        realizarán en conjunto con ustedes un análisis de marca,
                        para hacer el estudio detallado de sus necesidades y así
                        mismo establecer estrategias de marketing eficaces. Una
                        vez claro este punto, nos centramos en lograr un diseño
                        web a la medida que les permitirá aumentar la
                        visualización de los productos o servicios que ofrecen.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="container py-4" style="padding-left: 4%">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Desarrollo Web</p>
                      <p>
                        Con nosotros tienen la oportunidad de elegir un plan de
                        diseño que se ajuste a sus necesidades, así, nos
                        encargaremos de interpretar sus requerimientos y los de
                        su negocio, para enfocarnos en diseñar una manera
                        sencilla de comunicar el mensaje de su marca.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Aplicativos Web</p>
                      <p>
                        Diseñamos una herramienta informática para su entidad
                        que pueda&nbsp;realizar las mismas funciones a las
                        de&nbsp;aplicaciones de escritorio, con la ventaja de
                        ser accesible desde Internet.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">SEO</p>
                      <p>
                        El objetivo de nuestra estrategia es asegurarnos que el
                        sitio esté optimizado para ser más relevante a palabras
                        clave directamente relacionadas con la industria,
                        productos y/o servicios de su empresa.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="prod_audiovisual" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>PRODUCIÓN
                              AUDIOVISUAL</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="container" style="padding-left: 4%">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Instalaciones</p>
                      <p>
                        Contamos en nuestras instalaciones con equipos de
                        grabación, estabilización e iluminación de última
                        tecnología y un ciclorama que cuenta con todas las
                        capacidades técnicas para producciones vídeo y
                        fotografía profesionales.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Técnicas Innovadoras</p>
                      <p>
                        Utilizamos la fotografía de Alto Rango Dinámico (HDR)
                        que nos permite equilibrar las diferencias de luz, o los
                        Timelapses que son un complemento ideal al poder ver la
                        evolución de un día.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Calidad</p>
                      <p>
                        La finalidad de nuestro servicio es producir imágenes
                        originales, atractivas e impactantes, que transmitan un
                        mensaje y vendan una idea, son una inversión muy
                        rentable.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="img_corporativa" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>IMAGEN
                              CORPORATIVA</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="container" style="padding-left: 4%">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Logotipos</p>
                      <p>
                        Creamos diseño de logotipos para empresas fuera de lo
                        común, también existentes. El proceso de diseño de logo
                        requiere una idea clara del concepto y valores de la
                        marca, además de una comprensión del consumidor de la
                        audiencia
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Branding</p>
                      <p>
                        Dentro de nuestro equipo de trabajo interdisciplinar
                        gráficos especializados en el branding, diseño
                        audiovisual, editorial e impresos.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Arquitectura Corporativa</p>
                      <p>
                        Diseñamos y cuidamos cualquier objeto o imagen que
                        represente a la organización para que generen más
                        confianza, proponiendo una identidad corporativa con
                        coherencia y uniformidad que será percibida por el
                        público de una manera mucho más receptiva.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="ps_marca" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>POSICIONAMIENTO
                              DE MARCA</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                      <p>
                        En G & C Marketing y Publicidad le ayudamos a
                        transformar su forma de atraer y captar clientes a
                        través de Internet. Construimos comunidad alrededor de
                        su marca y la fidelizamos a través de contenidos y
                        automatización. Dividimos el proceso en cinco etapas:
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="container" style="padding-left: 4%">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Atracción</p>
                      <p>
                        Nosotros le ayudamos a transformar su manera de atraer y
                        captar clientes mediante la Internet. Construimos
                        comunidad alrededor de su marca y la fidelizamos con
                        contenidos y automatización. Le convertimos en una
                        autoridad en su sector; nosotros solo hablamos de
                        resultados.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Conversión</p>
                      <p>
                        En esta segunda etapa, comenzamos a captar la
                        información de nuestros visitantes para luego
                        perfilarlos y proyectamos al público objetivo. Es
                        importante brindar contenido de valor al cliente ideal.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Decisión</p>
                      <p>
                        Sabemos que todo usuario busca servicios o productos en
                        la web, pero antes de comprar necesita saber si lo que
                        busca saber si lo que busca puede satisfacer su
                        necesidad o no, por esta razón, se debe crear un
                        contenido que los incentive, En esta etapa comenzamos a
                        nutrir a sus prospectos y automatizar la relación con
                        ellos enfocándola en ventas.
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Ventas</p>
                      <p>
                        Todo el trabajo de generación de leads y relacionamiento
                        tiene como objetivo generar oportunidades de negocio
                        para su empresa. En algunos casos, el proceso de venta
                        ocurre dentro del propio sitio web: en otros, es
                        necesario el contacto entre un vendedor y el clinete
                        potencial.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Análisis</p>
                      <p>
                        Uno de los principales diferenciales del Marketing
                        Digital es poder evaluar con presición sus resultados y
                        comprobar el entorno de la inversión con base en los
                        hechos. Es posible monitorear cada acción que ocurre en
                        las iterasiones de su publico con su sitio, posts en
                        redes sociales y campañas pagas, lo que hace un trabajo
                        más inteligente.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="call_center" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>CALL
                              CENTER</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                      <p>
                        Ofrecemos una solución completa de callcenter para
                        compañías de todos los tamaños, nuestro servicio es
                        escalable y se ajusta a las necesidades de tanto
                        empresas en crecimiento como grandes compañías; estas
                        son las razones por la que lo invitamos a contratar
                        nuestro servicio de callcenter.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="container" style="padding-left: 4%">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Atención Personalizada</p>
                      <p>
                        Gracias a contratar un servicio de cali center se puede
                        ofrecer un servicio personalizado a cada cliente.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Campañas</p>
                      <p>
                        Alquilar un call center por campañas o temporadas
                        comerciales concretas, ayuda al aumento de ventas de
                        cualquier empresa. Y hoy en dia, es un factor a tener
                        encuenta.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Soporte a los Usuarios</p>
                      <p>
                        Si la empresa en cuestión se dedica a la venta de
                        diferentes productos, puede ofrecer mediante esta
                        solución una atención y un soporte a sus clientes.
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Ahorro Económico</p>
                      <p>
                        El disponer de un call center en las mismas
                        instalaciones que la empresa supone un gasto importante.
                        Externalizar este servicio es sinónimo de ahorro de
                        costos.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Valor Añadido</p>
                      <p>
                        Que una organización o una institución disponga de un
                        call center ofrece un valor agregado a la empresa ya que
                        se interesa en mantener un contacto directo con el
                        cliente final.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">
                        Servicio de Llamadas Entrantes
                      </p>
                      <p>
                        Como, por ejemplo, la petición de citas en caso de
                        negocios o instituciones que requieran de este servicio
                        para una mejor gestión y organización de su trabajo.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="hosting_datacenter" class="section-style">
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col col_text">
                      <section class="brand_title">
                        <b-row class="text-left py-3">
                          <b-col>
                            <span id="brand_title"
                              ><span id="fake">**********</span>HOSTING Y
                              DATACENTER</span
                            >
                          </b-col>
                        </b-row>
                      </section>
                      <p>
                        Nuestro departamento de programación desarrolla
                        soluciones a la medida según las necesidades específicas
                        de clientes en diversos sectores. Diseñadas para cubrir
                        los requerimientos técnicos de los entornos TIC
                        empresariales, los servicios de data center de
                        <B>G y C Marketing y Publicidad</B> son la mejor
                        alternativa para los clientes que quieren centrar sus
                        recursos en áreas que aportan mayor valor a su negocio.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Hosting</p>
                      <p>
                        Prestamos servicios de hosting para empresas y personas,
                        desde planes compartidos hasta servidores dedicados en
                        la nube. Empleado conexiones de alta velocidad para que
                        los datos de su website estén siempre online.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Soporte</p>
                      <p>
                        Proporcionamos a las empresas mantenimiento de la
                        infraestructura relacionada con sus Data Centers,
                        centrados en el aspecto correctivo y preventivo, para
                        anticipamos a posibles incidentes.
                      </p>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 col_text_sub">
                      <p class="sub_title_text">Servicio</p>
                      <p>
                        Para aquellas organizaciones que no disponen del espacio
                        adecuado o que prefieren ahorrar el presupuesto que de
                        otra manera tendrían que destinar a su centro de proceso
                        de datos, existe la alternativa de utilizar un data
                        center externo para alojar sus sistemas.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "About",
  title: "G y C Marketing | Nuestros servicios",
};
</script>
<style>
h1,
h2,
h3,
h4 {
  font-family: "Anton", sans-serif !important;
}

.font_title {
  color: black !important;
  font-size: 70px;
  font-family: "Anton", sans-serif !important;
  width: 100%;
  text-align: center;
}
.text_service {
  font-family: "Libre Baskerville";
  text-align: justify;
  color: #000000;
  font-size: 16px;
  padding: 0 22%;
}
.stripe-blue {
  background-color: #0ea5c6;
  min-height: 370px;
  text-align: center;
}
.img_colum {
  width: 100%;
  height: auto;
  margin-top: 5px;
}
.col_text {
  text-align: justify;
  font-size: 18px;
  font-family: "Libre Baskerville";
}
.sub_title_text {
  font-size: 22px;
  font-weight: bold;
}
.col_text_sub {
  text-align: justify;
  font-size: 16px;
  font-family: "Libre Baskerville";
}
.content_img {
  padding: 0 90px;
}

.section-style {
  padding-top: 20px;
}

#brand_title {
  font-weight: 400 !important;
  font-size: 22px;
  text-transform: uppercase;
  z-index: 999;
  font-weight: bold;
  position: relative;
}

#fake {
  background: #0ea5c6;
  margin-left: -25px;
  position: absolute;
  height: 20px;
  color: #0ea5c6;
  z-index: -1;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .page-info {
    font-size: 12px;
    color: black;
  }

  .content_img {
    padding: 0 !important;
  }

  .text_service {
    font-family: "Libre Baskerville";
    text-align: justify;
    color: #000000;
    font-size: 16px;
    height: auto;
    padding: 0 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }
}

@media screen and (max-width: 620px) {
  h1,
  h2,
  h3,
  h4 {
    font-family: "Anton", sans-serif !important;
  }

  .font_title {
    font-size: 30px;
  }

  .background-title {
    min-height: 230px;
  }

  .stripe-blue {
    height: auto;
  }

  .content_img {
    padding: 0 0;
  }

  .section-style {
    padding-top: 0px;
  }

  .content_img {
    padding: 0px !important;
  }

  .text_service {
    font-family: "Libre Baskerville";
    text-align: justify;
    color: #000000;
    font-size: 16px;
    padding: 0 0;
  }
}
</style>
